// TextInput.js
import React from 'react';

const TextInput = ({ type, placeholder, value, onChange, endAdornment, wrapperClass="", onBlur, label, error, className, disabled, onfocus, maxLength, required, id }) => {
  
   const inputClassName = `input form-control ${className ? className : ''} ${value ? 'has-value':''}`;
  
  return (<div className={`${wrapperClass} ${error ? 'redborder' : ''}`}>
    <input
      id={id}
      autoComplete="nope"
      type={type}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      placeholder={placeholder ? placeholder : ""}
      onFocus={onfocus}
      maxLength={maxLength}
      className={inputClassName.trim()}
      error={error}
    />
    <label className='label'>{label} {required && '*'}</label>
    {endAdornment && endAdornment()}
  </div>
  );
};

export default TextInput;
