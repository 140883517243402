
import { Jodit } from 'jodit-react'

const headerConfig = {
    readonly: false,
    toolbar: true,
    spellcheck: true,
    language: "en",
    uploader: {
        insertImageAsBase64URI: true
    }, 
    buttons: [
        ...Jodit.defaultOptions.buttons,
        // {
        //     name: 'insertDate',
        //     tooltip: 'Insert current Date',
        //     exec: (editor) => {
        //         editor.s.insertHTML(new Date().toDateString());
        //     }
        // },
        // {
        //     name: 'addBorder',
        //     tooltip: 'Add Border',
        //     exec: (editor) => {
        //         const tdElements = document.querySelectorAll('td');
        //         tdElements.forEach((td) => {
        //             td.style.border = '1px solid black';
        //         });
        //     }
        // },
    ]
};





export { headerConfig }

export const customStyles = {
    control: (provided) => ({
        ...provided,
        width: '100%',
        borderRadius: 0,
        textAlign: 'left',
    }),
    menu: (provided) => ({
        ...provided,
        width: '100%',
        textAlign: 'left',
    }),
};
