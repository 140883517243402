import React from 'react';
import Lottie from 'react-lottie';
import animationData from './logo.json'; 

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};


const Loader = ({className=''}) => {
    return (
        <div className="overlay"> 
            <div className='loader'>
                <Lottie
                    options={defaultOptions}
                    height={60}
                    width={200}
                    speed={1}
                /> 
                </div>  
        </div>
    );
};


export default Loader
