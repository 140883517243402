import React, { useEffect, useRef, useState } from 'react';
import { Bounce,  toast } from 'react-toastify';

const Upload = ({ handleUpload, handleContinue, isActive, setReset, reset }) => {
    const [file, setFile] = useState('');
    const [filePath, setFilePath] = useState('');

    const uploadRef = useRef()

    const handleInputChange = (event) => {  
        let file = event.target.files[0]
        if (file.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            toast.error('Invalid format, Please upload a document in XLS format.', {
                position: "top-right", 
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light", 
                transition: Bounce,
                title: 'Invalid format',
                message: 'Please upload a document in XLS format.',
                }); 
                setFilePath('')
                setFile('')
          } else{
            setFile(file);
            setFilePath(file.name)
            if(uploadRef.current && uploadRef.current.value){
                uploadRef.current.value = null; 
            }
          } 
       
    };

    const handleTextBoxClick = (event) => {
        uploadRef.current.click() 
    };

    const handleCancel = () => {
        setReset(true);
        setFilePath('')
            setFile('')
        if (uploadRef.current && uploadRef.current.value) {
            uploadRef.current.value = null; 
        }
        window.$("#generateacceptance").modal('hide');
    }
    
    useEffect(() => {
        if (reset) { 
            setFilePath('')
            setFile('')
        }
    }, [reset])

    return (
        <div className={`tab-pane fade  ${isActive? 'show active' : ''}`} id="v-pills-upload-document" role="tabpanel" aria-labelledby="v-pills-upload-document-tab" tabindex="0">
            <div className="container">
                <div className="row">
                    <div className="upload-document-content mt-2">
                        <h3 className='hd-ht'>Upload business requirement document</h3>
                        {/* <h6>Document format needs to be in XLS.</h6> */}
                        <span className='upload-instruction'>Ensure that the document adheres to the BR1091 XLS template, which is the template utilized for training the model.</span>
                        <div className="upload-button mt-4">
                            <div className="row">
                                <div className="col-md-6">
                                    <input ref={uploadRef}  accept=".xls, .xlsx" id="file-upload" type="file" onChange={(e) => { handleInputChange(e);  }} style={{ display: 'none' }} />
                                    <input
                                        type="text"
                                        value={filePath} 
                                        onChange={ (e) => handleInputChange(e)}
                                        onClick={(e)=> handleTextBoxClick(e)}
                                        placeholder="Choose file..."
                                        readOnly
                                        className="file-path-input input form-control"

                                    />
                                </div>
                                <div className="col-md-2">
                                    <button disabled={!file} onClick={() =>  handleUpload(file)} className={`btn-dt ${!file ? 'disabled': ''}`}>Upload</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="row btn-area">
                    <div className="col-md-6">
                        {/* <div className={`continue-btn`}>
                            <button className={`${!file ? 'disabled': ''}`} disabled={!file} onClick={()=> handleContinue()} >Continue</button>
                        </div> */}
                    </div>
                    <div className="col-md-6">
                        <div className="cncl">
                            <button onClick={() => {handleCancel() }}>Cancel</button> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Upload;