function Enum() {
    this.self = arguments[0];
  }
  Enum.prototype = {
    keys: function () {
      return Object.keys(this.self);
    },
    values: function () {
      var me = this;
      return this.keys(this.self).map(function (key) {
        return me.self[key];
      });
    },
    getKeyValuePairs: function () {
      var me = this;
      return this.keys(this.self).map(function (key) {
        return { name: key, value: me.self[key] };
      });
    },
    getValueByName: function (key) {
      return this.self[
        this.keys(this.self)
          .filter(function (k) {
            return key.toString() === k;
          })
          .pop() || ''
      ];
    },
    getNameByValue: function (value) {
      var me = this;
      return (
        this.keys(this.self)
          .filter(function (k) {
            return me.self[k] === value;
          })
          .pop() || null
      );
    }
  };
  
export const FileName = {
    FILEANDINSTRUCTIONS : 'File and Instructions',
    TRANSCATIONLEVELBUSINESSRULE : 'TRANSCATION LEVEL BUSINESS RULE',
    TRANSACTIONLEVELBUSINESSRULE : 'TRANSACTION LEVEL BUSINESS RULE',
    USERSTORIES : 'USER STORIES'
} 

export const ProjectStatus = {
  Pending: 0,
  InReview: 1,
  Approved: 2,
}

export const ProjectStatusDesc = new Enum({
  0 : "Pending",
  1 : "In Review", 
  2 : "Approved", 
})