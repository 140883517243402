import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

const ScrollToTop = (props) => {
  const { pathname  } = useLocation();
  const { children } = props;

  useEffect(() => { 
    if (pathname) { 
      window.scrollTo(0, 0)
    }
  }, [pathname]);

  return children;
};

ScrollToTop.prototype = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  children: PropTypes.element.isRequired,
};
export default ScrollToTop;
