import React from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
import { connect } from 'react-redux';
import { Logout } from '../../../store/auth/action';


const Header = ({ Logout }) => {
  const navigate = useNavigate();
  
  const handleLogout = (e) => {
    e.preventDefault();
    Logout();
    navigate("/login");
  };

  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          <div className="logo-dt">
            <a href="#" className="logo logo-main">UseCase AI</a>
          </div>

        </div>
        <div className="d-flex">
          <div className="navbar-brand-box-data"></div>

          {/* <div className="dropdown d-lg-inline-block ms-1">
            <button type="button" className="btn header-item noti-icon waves-effect">
              <Icon icon="bi:bell-fill" />

            </button>
          </div> */}

          <div className="dropdown d-inline-block">
            <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <Icon icon="eos-icons:admin" />
            </button>
            <div className="dropdown-menu dropdown-menu-end">
              <Link className="dropdown-item text-danger" to='/' onClick={handleLogout}><span className="iconify" data-icon="ri:logout-circle-r-line"></span><span>Logout</span></Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

const mapDispatchToProps = { Logout };

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
