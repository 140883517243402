import React, { useState } from 'react';
import { CreateProject, GeneratePdf, GetAcceptanceCriteria, GetRules, GetUserStories } from '../../store/project/action';
import { connect } from 'react-redux';
import { ProjectStatus } from '../../util/enum';
import JoditReact from 'jodit-react';
import { headerConfig } from '../../util/jodit'; 
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Icon } from '@iconify/react';
import { convertToHTML } from '../../util/helper';
import _ from 'lodash';

function BusinessRule({ CreateProject, isApproval, userStories, setUserStories,
    topicNumber, topicDescription, businessRules, setBusinessRules, isActive, setActiveIndex,
    setProjectId, projectId, setLoading, GetAcceptanceCriteria, setRefresh, GetUserStories, GetRules, setReset, GeneratePdf, setProject }) {
    const [ruleIndex, setRuleIndex] = useState(0);

    const handleRuleChange = (event) => {
        setRuleIndex(event?.target?.value)
    };

    const handleRuleTextChange = async (event) => { 
        let value = event.target.value  
        const copyRules = [...businessRules];
        copyRules[ruleIndex].description = value;
        setBusinessRules(copyRules)
    }

    const handleUserStoryTextChange = (field, value, index) => {
        const copyUserStories = [...userStories];
        copyUserStories[index][field] = value;
        setUserStories(copyUserStories)
    }

    const handleSaveContinue = async () => {
        setLoading(true)
        let payload = {
            topicNumber: topicNumber,
            description: topicDescription,
            status: ProjectStatus.Pending,
            userstories: userStories,
            rules: businessRules
        }
        if (projectId) {
            payload._id = projectId
        }
        const res = await CreateProject(payload);
        if (res?._id) {
            setProjectId(res?._id)
            const rules = await GetRules(res?._id);
            setBusinessRules(rules)
            const userstories = await GetUserStories(res?._id);
            setUserStories(userstories) 
            const userStoryData = await GetAcceptanceCriteria(projectId || res?._id);
            setUserStories(userStoryData)
            setActiveIndex(2)
            setLoading(false)
            setRefresh(true)
        } else {
            setLoading(false)
            setRefresh(true)
        }

    }

    const handleSave = async () => {
        let payload = {
            topicNumber: topicNumber,
            description: topicDescription,
            status: userStories[0]?.acceptanceCriteria ? ProjectStatus.InReview : ProjectStatus.Pending,
            userstories: userStories,
            rules: businessRules
        }
        if (projectId) {
            payload._id = projectId
        }
        const res = await CreateProject(payload);
        if (res?._id) {
            setProjectId(res?._id)
            const rules = await GetRules(res?._id);
            setBusinessRules(rules)
            const userstories = await GetUserStories(res?._id);
            setUserStories(userstories)
        }
        toast.success('Your changes have been saved successfully.', {
            autoClose:true,
            position: "top-right", 
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light", 
            transition: Bounce,
            });
        setRefresh(true)
    }

    const handleApprove = async () => {
        let payload = {
            topicNumber: topicNumber,
            description: topicDescription,
            status: ProjectStatus.Approved,
            userstories: userStories,
            rules: businessRules
        }
        if (projectId) {
            payload._id = projectId
        }
        const res = await CreateProject(payload);
        if (res?._id) {
            setProjectId(res?._id)
        }
        setRefresh(true)
        setReset(true)
        setProject(null)
        window.$("#generateacceptance").modal('hide')
    }

    const handleGenerate = async () => {
        setLoading(true)
        const res = await GeneratePdf(projectId);
        var blob = new Blob([res.buffer], { type: "Buffer" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = `${topicNumber} ${topicDescription}.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
        setLoading(false)
    }   

    return (
        <div class={`tab-pane fade  ${isActive ? 'show active' : ''}`} id="v-pills-review-generate" role="tabpanel" aria-labelledby="v-pills-review-generate-tab" tabindex="0">
            <div className="container">
                <div className="row">
                    <div className="review-generate">
                        <div className="rv-gn-accodian mt-4">
                            <div class="accordion accordion-flush" id="accordionreviewgenerate">
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#reviewgenerate-collapseOne" aria-expanded="false" aria-controls="reviewgenerate-collapseOne">
                                            Business Rules (Project #: {topicNumber} {topicDescription})
                                        </button>
                                    </h2>
                                    <div id="reviewgenerate-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionreviewgenerate">
                                        <div class="accordion-body">
                                            <div className='container'>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <select id="business-rule-select" onChange={handleRuleChange} className='form-select'>
                                                            <option value="">Select business rule</option>
                                                            {businessRules && businessRules.length > 0 && businessRules.map((item, index) => {
                                                                return <option value={index}>{item.name}</option>
                                                            })}
                                                            {/* Add more options as needed */}
                                                        </select>
                                                    </div>
                                                    {ruleIndex != '' && <div className="col-md-12 mt-3">
                                                       <textarea
                                                            value={businessRules && businessRules[ruleIndex]?.description || ""}
                                                            onChange={handleRuleTextChange}
                                                            placeholder="Enter additional details..."
                                                            rows={5}
                                                            cols={50}
                                                            className='form-control'
                                                        />  
                                                        {/* <JoditReact
                                                            value={businessRules[ruleIndex]?.description ? convertToHTML(businessRules[ruleIndex]?.description) : ""}
                                                            onBlur={handleRuleTextChange}
                                                            placeholder="Enter additional details..."
                                                            config={headerConfig}
                                                            tabIndex={3}
                                                        /> */}
                                                    </div>}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {userStories && userStories.length && _.orderBy(userStories, ['userStoryId'], ['asc']).map((story, index) => {
                                    return <div class="accordion-item">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#reviewgenerate-collapse_${index}`} aria-expanded="false" aria-controls={`reviewgenerate-collapse_${index}`}>
                                                User Story # {story.userStoryId}
                                            </button>
                                        </h2>
                                        <div id={`reviewgenerate-collapse_${index}`} class="accordion-collapse collapse" data-bs-parent="#accordionreviewgenerate">
                                            <div class="accordion-body">
                                                <div className="user-story-details">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <label htmlFor="additional-details">Name:</label>
                                                            <textarea
                                                                id="name-details"
                                                                value={story.name}
                                                                onChange={(event) => handleUserStoryTextChange('name', event.target.value, index)}
                                                                placeholder="Name"
                                                                rows={1}
                                                                cols={50}
                                                                className='form-control mt-2'
                                                            />
                                                        </div>
                                                        <div className="col-md-12 mt-3">
                                                            <label htmlFor="description-details">Description:</label>
                                                            <textarea
                                                                id="description-details"
                                                                value={story.description}
                                                                onChange={(event) => handleUserStoryTextChange('description', event.target.value, index)}
                                                                placeholder="Description"
                                                                rows={5}
                                                                cols={50}
                                                                className='form-control mt-2'
                                                            />
                                                        </div>
                                                        {isApproval && <div className="col-md-12 mt-3">
                                                            <label htmlFor="acceptance-details">Acceptance criteria:</label>
                                                            {/* <JoditReact
                                                                rows={5}
                                                                cols={50}
                                                                config={headerConfig}
                                                                value={story.acceptanceCriteria? convertToHTML(story.acceptanceCriteria) : ""}
                                                                placeholder="Start typing or paste your text here. Or try our new AI writing tool that will write for you!" /> */}
                                                              <textarea
                                                                id="acceptance-details"
                                                                onChange={(event) => handleUserStoryTextChange('acceptanceCriteria', event.target.value, index)}
                                                                placeholder="Acceptance criteria"
                                                                rows={5}
                                                                cols={50}
                                                                value={story.acceptanceCriteria || ""}
                                                                className='form-control mt-2'
                                                            />  
                                                        </div>}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row btn-area">
                    <div className="col-md-6 d-flex">
                        <div className="continue-btn">
                            <button onClick={() => handleSave()}>Save</button>

                        </div>
                        <div className="continue-btn">
                            {!isApproval ? <button onClick={() =>handleSaveContinue()}>Continue</button> : 
                                    <button onClick={() =>handleApprove()} >Approve</button>
                                 }
                        </div> 
                        {isApproval && <div className="pdf-btn">
                            <button onClick={() => handleGenerate()} ><Icon icon="streamline:convert-pdf-2" fontSize={18} color='red' /> View</button>
                        </div>} 
                    </div>
                    <div className="col-md-6">
                        <div className="cncl">
                            <button onClick={() => { setProject(null); window.$("#generateacceptance").modal('hide'); setReset(true) }}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    );
}
const mapDispatchToProps = { CreateProject, GetAcceptanceCriteria, GetUserStories, GetRules, GeneratePdf };

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessRule);  
