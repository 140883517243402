import client from "../../services/api-client";
import { errorHandler } from "../error/action";


export const UploadFile = (data) => async (dispatch) => {
    try {
        const res = await client.service('upload-v1').create({ file: data });
        if (res) {
            return res
        }
    } catch (err) {
        errorHandler(err);
        return false
    }
}

export const CreateProject = (data) => async (dispatch) => {
    try {
        if (!data._id) {
            const res = await client.service('project').create(data);
            return res
        } else {
            const res = await client.service('project').patch(data._id, data);
            return res
        }
    } catch (err) {
        errorHandler(err);
        return false
    }
}

export const GetProjects = () => async (dispatch) => {
    try {
        const res = await client.service('project').find();
        return res
    } catch (err) {
        errorHandler(err);
        return false
    }
}

export const GetRules = (id) => async (dispatch) => {
    try {
        const res = await client.service('rules').find({ query: { projectId: id } });
        return res
    } catch (err) {
        errorHandler(err);
        return false
    }
}

export const GetUserStories = (id) => async (dispatch) => {
    try {
        const res = await client.service('userstories').find({ query: { projectId: id } });
        return res
    } catch (err) {
        errorHandler(err);
        return false
    }
}

export const GetAcceptanceCriteria = (projectId) => async (dispatch) => {
    try { 
        const res = await client.service('acceptance-criteria-v1').find({ query: { projectId: projectId } });
        return res
    } catch (err) {
        errorHandler(err);
        return false
    }
}

export const GeneratePdf = (projectId) => async (dispatch) => {
    try {
        const res = await client.service('generate-pdf-v1').find({ query: { projectId: projectId } });
        return res
    } catch (err) {
        errorHandler(err);
        return false
    }
}