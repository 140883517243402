import BusinessRule from "../BusinessRule"
import Upload from "../Upload"
import Loader from '../Loader'
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { UploadFile } from "../../store/project/action"
import { FileName } from '../../util/enum'
import { Bounce,  toast } from 'react-toastify';

const AcceptanceCriteria = ({ UploadFile, project, setRefresh, setProject }) => {
    const [topicNumber, setTopicNumber] = useState(null);
    const [topicDescription, setTopicDescription] = useState(null);
    const [projectId, setProjectId] = useState(null);

    const [businessRules, setBusinessRules] = useState([]);
    const [userStories, setUserStories] = useState([]);

    const [loading, setLoading] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [reset, setReset] = useState(false);

    useEffect(() => {
        if (project) {
            setLoading(true)
            setTopicNumber(project?.topicNumber)
            setTopicDescription(project?.description)
            setProjectId(project?._id)
            setBusinessRules(project?.rules)
            setUserStories(project?.userstories)
            setActiveIndex(project?.topicNumber ? 1 : 0)
            setLoading(false) 
        } else { 
            setTopicNumber(null)
            setTopicDescription(null)
            setProjectId(null)
            setBusinessRules(null)
            setUserStories(null)
            setActiveIndex(0)  
        }
    }, [project])

    useEffect(() => {
        if (reset == true) {
            setTopicNumber(null)
            setTopicDescription(null)
            setProjectId(null)
            setBusinessRules(null)
            setUserStories(null)
            setActiveIndex(0)
        }
    }, [reset])

    const handleUpload = async (file) => {
        setLoading(true)
        const res = await UploadFile(file)
        if(res && res.error){
            toast.error('Data extraction failed. Please ensure your document matches the trained template and try uploading again. If the issue persists, contact support for assistance.', {
                position: "top-right", 
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light", 
                transition: Bounce, 
                }); 
            setLoading(false)
            setReset(true)
        } else {
            if (res && Object.keys(res).length) {
                Object.entries(res).map(async (value) => {
                    const key = value[0];
                    const data = value[1] 
                    if (key.toLowerCase() == FileName.FILEANDINSTRUCTIONS.toLowerCase()) {
                        const topicNumber = data[0] && data[0].length && data[0][1].slice(0, data[0][1].indexOf(' ')) || ""
                        const topicDescription = data[0] && data[0].length && data[0][1].slice(data[0][1].indexOf(' ') + 1) || ""
                        setTopicNumber(topicNumber)
                        setTopicDescription(topicDescription)
                    }
                    if (key.toLowerCase() == FileName.TRANSCATIONLEVELBUSINESSRULE.toLowerCase() || key.toLowerCase() == FileName.TRANSACTIONLEVELBUSINESSRULE.toLowerCase()) {
                        const filteredData = data.filter(row => row[1] !== null && row[1].trim() !== '');
                        let businessRules = [] 
                        await Promise.all(filteredData.map((c, i) => {
                            if (i == 0) {
                                return;
                            }
                            businessRules.push({ name: c[0], description: c[1] })
                        }))
                        setBusinessRules(businessRules)
                    }
                    if (key.toLowerCase() == FileName.USERSTORIES.toLowerCase()) {
                        const filteredData = data.filter(row => row[1] !== null && row[1].trim() !== '');
                        let userStories = []
                        await Promise.all(filteredData.map((c, i) => {
                            if (i == 0) {
                                return;
                            }
                            userStories.push({ userStoryId: c[0], name: c[1], description: c[2], defaultAcceptanceCriteria: c[3] })
                        }))
                        setUserStories(userStories)
                    }
                })
            }
            setActiveIndex(1)
            setLoading(false)
        } 

    }; 

    const handleContinue = () => {
        setActiveIndex(1)
        setLoading(false)
    } 

    return <div class="modal fade" id="generateacceptance" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered modal-xl" style={{ maxWidth: '1350px', }}>
            <div class="modal-content" style={{ height: '600px' }}>
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="staticBackdropLabel">Generate acceptance criteria</h1>
                    {/* <button type="button" class="btn-close" onClick={() => { setReset(true); window.$("#generateacceptance").modal('hide'); }}></button> */}
                </div>
                <div class="modal-body">
                    <div className="custom-extration-data">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="custom-left">
                                        <div class="nav flex-column nav-pills " id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                            <ul>
                                                <li className={`nav-link ${activeIndex == 0 ? 'active' : ''}`} onClick={() => { (topicNumber == null || !userStories[0].acceptanceCriteria) &&  setActiveIndex(0) }}>Upload document</li>
                                                <li className={`nav-link ${activeIndex == 1 ? 'active' : ''}`} onClick={() => { topicNumber == null ? setActiveIndex(0) : setActiveIndex(1) }}>Review and generate</li>
                                                <li className={`nav-link ${activeIndex == 2 ? 'active' : ''}`} onClick={() => { topicNumber == null ? setActiveIndex(0) : userStories[0].acceptanceCriteria && setActiveIndex(2) }}>Review and approve</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-10 br-left">
                                    <div className="custom-right">
                                        {loading && <Loader />}
                                        <div class="tab-content" id="v-pills-tabContent">
                                            <Upload handleUpload={handleUpload} handleContinue={handleContinue} isActive={activeIndex == 0} setActiveIndex={setActiveIndex} setReset={setReset} reset={reset}/>
                                            <BusinessRule isApproval={false} userStories={userStories} setUserStories={setUserStories}
                                                topicNumber={topicNumber} topicDescription={topicDescription}
                                                businessRules={businessRules} setBusinessRules={setBusinessRules} 
                                                isActive={activeIndex == 1} setActiveIndex={setActiveIndex} 
                                                setProjectId={setProjectId} projectId={projectId} setLoading={setLoading}
                                                setRefresh={setRefresh} setReset={setReset} setProject={setProject} />
                                            <BusinessRule isApproval={true} userStories={userStories} setUserStories={setUserStories} 
                                                topicNumber={topicNumber} topicDescription={topicDescription}
                                                businessRules={businessRules} setBusinessRules={setBusinessRules} 
                                                isActive={activeIndex == 2} setActiveIndex={setActiveIndex}
                                                setProjectId={setProjectId} projectId={projectId} setLoading={setLoading}
                                                setRefresh={setRefresh} setReset={setReset} setProject={setProject}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
}

const mapDispatchToProps = { UploadFile };

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AcceptanceCriteria);  