import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import TextInput from '../../components/TextInput'
import { Icon } from '@iconify/react'
import { Login } from '../../store/auth/action';
import { connect } from 'react-redux';
import validate from "validate.js";
import { schema } from './schema';
import { isEmpty } from 'lodash';

const LoginScreen = ({ Login }) => {
  const navigate = useNavigate(); 
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState();
  const initialState = {
    values: {
      email: "",
      password: "", 
      rememberMe:false
    },
    errors: {
      email: null,
      password: null, 
    },
  };
  const [values, setValues] = useState(initialState.values);
  const [errors, setErrors] = useState(initialState.errors);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const obj = localStorage.getItem("rememberMe") && localStorage.getItem("rememberMe") !== "undefined" ? JSON.parse(localStorage.getItem("rememberMe")) : null;
    if (obj !== null) {
      setValues((prev) => ({
        ...prev,
        email: obj.email,
        password: obj.password,
        rememberMe: obj.rememberMe,
      }));
    }
  }, []);

  const handleChange = (field, value) => {
    if (field === "email") {
    } else if (field === "password") {
    }
    setValues((prev) => ({ ...prev, [field]: value }));
  }; 

  const validateForm = () => {
    const errors = validate(values, schema);
    setErrors(errors);
    if (errors) {
      const message =
        !isEmpty(values.email) && !isEmpty(values.password)
          ? errors[Object.keys(errors)[0]][0]
          : "Please enter your email and password to log in.";
      setMessage(message);
      window.$("#errorModal").modal('show');
      return false;
    }
    return true;
  };

  const handleLogin = async (e) => {
    let valid = await validateForm();
    if (valid) {
      setLoading(true); 
      const res = await Login(values);
      if (res) {
        navigate('/project-list');
      } else {
        setLoading(false)
      }
    }
  };

  const handleRememberme = (event) => {
    setValues({ ...values, rememberMe: event.target.checked });
  };

  const handleTogglePassword = () => {  
    setShowPassword(!showPassword);
  };
 
   

  return (
  <section class="login-section">
    <div class="container container-lg container-md">
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
          <div class="login-content">
            <div class="logo">
              <h2 class="log-fnt">UseCase<span class="log-360"> AI</span></h2>
              <p>AI-Powered UseCase. From Vision to Verification.</p>
            </div> 
            <TextInput
                    wrapperClass='inputContainer user-name'
                    type='email'
                    value={values.email}
                    onChange={(e) => handleChange("email", e.target.value)}
                    label='Email'
                    name="email"
                    id='email'
                    error={errors && errors?.email}
                  />   
              <TextInput
                wrapperClass='inputContainer mb-0'
                type={showPassword ? 'text' : 'password'}
                value={values.password}
                endAdornment={() => <span className='iconPassword' onClick={handleTogglePassword}>
                  {showPassword ? <Icon icon="eva:eye-outline" /> : <Icon icon="tabler:eye-off" />}
                </span>}
                onChange={(e) => handleChange("password", e.target.value)}
                label='Password'
                name='password'
                id='password'
                className="pad-right"
                error={errors && errors?.password}
              />  

            <div class="d-grid col-12 mx-auto mt-4 mb-20">
              <div class="btnbox">
                <Link onClick={handleLogin} class="fullwidthbtn ft-size" type="button">
                  <div class="loader"></div>
                  Login
                </Link>
              </div>
            </div>
            <div class="login-options">
                    <div class="rememberme">
                      <label id="remebercheck">
                        <input type="checkbox" name="checkbx" onClick={handleRememberme} value="remebercheck" checked={values.rememberMe} />
                        Remember me
                      </label>
                    </div>
                  </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

const mapDispatchToProps = { Login };

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);