import Footer from "./Footer";

const DefalutLayout = ({ children }) =>{
   return (<>
   {/* <Header/> */}
      {children}
      <Footer />
   </>)
}

export default DefalutLayout