import store from "..";

export const errorHandler = async (err) => {
  let { message } = err; 
  switch (err.code) {
    case 401: // auth failed
      if (
        err.name === "invalid signature" ||
        err.name === "Not authenticated" ||
        err.name === "jwt expired"
      ) {
        // message = "Your session is expired. Please re-login";
        localStorage.removeItem("feathers-jwt");
        localStorage.removeItem("persist:root");
        await store.dispatch({
          type: "LOGOUT",
        });
      } else if (err.message === "SMS is not supported by landline phone number") {
        message = "SMS is not supported by landline phone number, please contact administrator.";
      }
      else if ((err.message === "Email ID or password is incorrect") || (err.message === "Invalid login")) {
        message =  err.message
      } else if (err.message === "OTP wrong.") {
        message = "You have entered a wrong code.";
      } else if (err.message === "jwt expired" || err.message === "Not authenticated") {
        message = "Your session has expired. Please log in again.";
      } else if (err.message === "Too many requests") {
        message = "Too many requests";
      } else if (err.data && err.data.text === "OTP sent") {
        store.dispatch({
          type: "OTP_VERIFY",
          data: err.data,
        });
        return true;
      }
      break;
    case 400: //validation error
      if (err) {  
        const error = err;
        if(error?.message?.includes("ER_DUP_ENTRY: Duplicate entry")){
          message = "email must be unique"
        }
      } 

      break;
    case 404: // server error
      message = "Opps... something went wrong";
      break;
    case 408: // timesout
      message = "Request timed out, please try again";
      break;
    default:
      break;
  }

  store.dispatch({
    type: "SHOW_ERROR",
    data: { ...err, message },
  });
};

export const removeError = () => {
  return store.dispatch({
    type: "REMOVE_ERROR",
    data: {},
  });
};
