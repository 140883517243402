import React from 'react';
import { Route, Routes } from 'react-router-dom';
import routes from "./routes";
import ProtectedRoute from "../layouts/ProtectedLayout";
import ErrorBar from '../components/ServerModal';

const Router = () => {
  return (
    <main>
      <Routes>
        {/* Render each route directly */}
        {routes && routes.map(({ path, View, isProtected, Layout }) =>
          isProtected ? <Route path={path} key={path} element={
            <Layout children={<ProtectedRoute>
              <View />
            </ProtectedRoute>} />
          } /> :
            <Route path={path} key={path} element={<Layout children={<View />} />} />
        )}
        {/* Add a fallback route for unmatched paths */}
      </Routes>
      <ErrorBar />
    </main>
  );
}

export default Router;
