import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import AcceptanceCriteria from '../../components/AcceptanceCriteria';
import { GeneratePdf, GetProjects, GetRules, GetUserStories } from '../../store/project/action';
import moment from 'moment';
import { ProjectStatus, ProjectStatusDesc } from '../../util/enum';
import Loader from '../../components/Loader';
import { ToastContainer } from 'react-toastify';

const ProjectList = ({ GetProjects, GetRules, GetUserStories, GeneratePdf }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]) 
    const [project, setProject] = useState({})
    const [refresh, setRefresh] = useState(true)
    const [loading, setLoading] = useState(false); 
    const itemsPerPage = 10; // Adjust as needed

    useEffect(() => {
        async function fetch() {
            const result = await GetProjects() 
            setData(result)
            setRefresh(false)
        }
        fetch()
    }, [refresh]) 

    const handleSearch = async (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1); // Reset to the first page when searching 
        const result = await GetProjects(e.target.value)
        setData(result) 
    }; 
    
    // Function to handle changing tabs
    const handleOpen = async (e, item) => {
        e.preventDefault();
        setLoading(true)  
        let payload = item;  
        const rules = await GetRules(item._id);
        payload.rules = rules;
        const userstories = await GetUserStories(item._id);
        payload.userstories = userstories;
        setProject(payload) 
        window.$("#generateacceptance").modal('show')
        setLoading(false)  
    };

    const handleGenerate = async ({ _id, topicNumber, description }) => { 
        setLoading(true)
        const res = await GeneratePdf(_id);
        var blob = new Blob([res.buffer], { type: "Buffer" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = `${topicNumber}-${description}.pdf`;
        a.click();
        // window.open(url, '_blank');
        window.URL.revokeObjectURL(url);
        setLoading(false)
    }

    return (<>
        <div className="main-content">
        {loading && <Loader />}  
         <ToastContainer limit={1} toastClassName="custom-toast"/>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 padding-left-10px">
                            {/* <div className="bread-crumbs">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link>Dashboard</Link></li>
                                        <li className="breadcrumb-item active"><Link >List</Link></li>
                                    </ol>
                                </nav>
                            </div> */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex uat-li">
                                <h1 className="pat-head">My Projects</h1>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex ">
                                        <div className="wit-90 uat-li">
                                            <button onClick={() => { setProject(null) ; window.$("#generateacceptance").modal('show') }}><Icon icon="ic:baseline-plus" />Create new</button>
                                        </div>
                                        {/* <div className="search-bar">
                                            <input type="text" value={searchQuery} onChange={handleSearch} placeholder="Search..." />
                                        </div> */}
                                    </div>


                                    <table className="table dt-responsive list-view-table dataTable no-footer mt-3" style={{ width: '100%' }}>
                                        <thead className="table-header">
                                            <tr>
                                                <th>Project #</th>
                                                <th>Description </th>
                                                <th>Created</th>
                                                <th>Status</th>
                                                <th>Report</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data && data.total > 0 ? data.data.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.topicNumber}</td>
                                                    <td>{item.description}</td>
                                                    <td>{moment(item.createAt).format("MM/DD/YYYY")}</td>
                                                    <td>
                                                        <div className="d-flex align-items-center it-sts">
                                                            {item.status === ProjectStatus.Approved && (
                                                                <div> <Icon icon="tabler:progress-check" className='green-icon' />{ProjectStatusDesc.getValueByName(item.status)}</div>
                                                            )}
                                                             {item.status === ProjectStatus.InReview && (
                                                                <Link to={"/"} className='cursor-pointer' onClick={(e) => handleOpen(e, item)} > <Icon icon="humbleicons:clock" className='blue-icon'/>{ProjectStatusDesc.getValueByName(item.status)}</Link>
                                                            )}
                                                            {item.status === ProjectStatus.Pending && (
                                                                <Link className='cursor-pointer' onClick={(e) => handleOpen(e, item)} >  <Icon icon="mdi:progress-alert" className='orange-icon' />{ProjectStatusDesc.getValueByName(item.status)}</Link>
                                                            )} 
                                                        </div>
                                                    </td>
                                                    <td>
                                                    {item.status > ProjectStatus.Pending ? <div className='cursor-pointer' onClick={() => handleGenerate(item)} ><Icon icon="streamline:convert-pdf-2" fontSize={20} color='red'/></div> : '--' }
                                                    </td>
                                                </tr>
                                            ))
                                                : <tr>
                                                    <td colSpan="5" style={{ textAlign: 'center' }}>There are no records to display. Click “Create new” to start your first project.</td>
                                                </tr>}
                                        </tbody>

                                    </table>
                                    {/* <Pagination
                                        totalPages={totalPages}
                                        currentPage={currentPage}
                                        onPageChange={handlePageChange}
                                        className="paging-st"
                                    /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AcceptanceCriteria project={project} setRefresh={setRefresh} setProject={setProject}/>
    </>
    );
};

const mapDispatchToProps = { GetProjects,  GetRules, GetUserStories, GeneratePdf };

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);