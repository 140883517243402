import React, { useEffect, useState } from 'react';
import Router from '../src/routes';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS before custom SCSS
import 'bootstrap';
import "../src/scss/app.scss";
import store from "./store";
import { IsAlive } from "./store/auth/action";
import ScrollToTop from "./ScrollToTop";
import { createBrowserHistory } from 'history';
import { persistStore } from 'redux-persist';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";

const browserHistory = createBrowserHistory();

// Bind persist store with redux store
const persistor = persistStore(store);
function App() {
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoading(false);
    setTimeout(() => setLoaded(true), 500);
  }, []);

  const onBeforeLift = async () => {
    const res = await IsAlive();
    const path = browserHistory.location.pathname;
    if (res) { 
      if (path === "/" || path === "/login") {
        browserHistory.push("/project-list");
        return true;
      }
      return true
    } else {
      await store.dispatch({
        type: "LOGOUT",
      });
      browserHistory.push("/login");
        return true;
    }
    return true
  };

  const loader = () => {
    const loadStyle = {
      position: "absolute",
      top: "40%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      width: "75px",
      height: "75px",
    };

    return <span style={loadStyle}></span>;
  };

  return (
    <Provider store={store}>
      <PersistGate
        persistor={persistor}
        onBeforeLift={() => onBeforeLift()}
        loading={loader}
      >
        <BrowserRouter basename="/">
          <ScrollToTop />
          {/* {!loaded && <LottieLoader loading={loading} />} */}
          <Router />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
