import React from 'react';
import moment from 'moment';


const Footer = () => {
  return (<>
  <footer>
    <div class="col-12 text-center ftr-top">
        <span>© {moment().format('YYYY')} Microsan Consultancy Services</span>
    </div>
</footer>

</>  )
}

export default Footer