export const AuthReducer = (state = {}, action) => {
    switch (action.type) {
      case "LOGIN":
        return {
          ...state,
          profile: { ...action.data },
        }; 
      case "LOGOUT":
          return {
            ...state,
            profile: null,
          };
      default:
        return state;
    }
  };