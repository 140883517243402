import LoginLayout from "../layouts/LoginLayout";
import DashboardLayout from "../layouts/DashboardLayout";
import Login from '../containers/Login'; 
import ProjectList from "../containers/ProjectList";

//routes

export default [

    {
        path: "/login",
        View: Login,
        isProtected: false, 
        Layout: LoginLayout,
    },
    {
        path: "/project-list",
        View: ProjectList,
        isProtected: true, 
        Layout: DashboardLayout,
    },

]